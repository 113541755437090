const navLinks = [
  {
    id: 1,
    name: '.projects',
    section: '/#projects'
  },
  {
    id: 2,
    name: '.about',
    section: '/#about'
  },
  {
    id: 3,
    name: '.contact',
    section: '/#contact'
  },
  // {
  //   id: 4,
  //   name: '.resume',
  //   section: '/resume',
  //   route: '/resume'
  // },
]

export default navLinks