const projects = [
  {
    "id": 1,
    "name": ".Artist Website",
    "image": require("../../images/saskiaswebsite.webp"),
    "description": "A webiste built for a client.",
    "deployedLink": "https://www.saskiaspender.com/",
    "githubLink": "https://github.com/Sophie-Senge/Saskia-Webpage",
    "tools": [">React", ">Tailwind"],
    "className": "h-22 w-22 rounded-tr-extraLarge rounded-bl-extraLarge cursor-pointer p-4 transition duration-500 hover:scale-110 shadow-lg filter grayscale hover:grayscale-0 border-0 hover:border-indigo-500"

  },
 
  {
    "id": 2,
    "name": ".90s Quiz",
    "image": require("../../images/quizPhoto.png"),
    "description": "A fun 90s themed timed quiz with multiple-choice questions.",
    "deployedLink": "https://sophie-senge.github.io/90s-quiz/",
    "githubLink": "https://github.com/Sophie-Senge/90s-quiz",
    "tools": [">HTML5",  ">CSS3", ">JavaScript", ">Bootstrap"],
    "className": "h-22 w-22 rounded-tl-extraLarge rounded-br-extraLarge cursor-pointer p-4 transition duration-500 hover:scale-110 shadow-lg filter grayscale hover:grayscale-0 border-0 hover:border-indigo-500"

  },
  {
    "id": 3,
    "name": ".Better Nutrient",
    "image": require("../../images/BN-screenshot.webp"),
    "description": "A collaborative effort built to fetch the nutritional values of any searched food item as well as the top eight recipes from around the web including links to the full recipes.",
    "deployedLink": "https://sophie-senge.github.io/Better-Nutrient/",
    "githubLink": "https://github.com/Sophie-Senge/Better-Nutrient",
    "tools": [">HTML5",  ">CSS3", ">JavaScript", ">APIs"],
    "className": "h-22 w-22 rounded-tr-extraLarge rounded-bl-extraLarge cursor-pointer p-4 transition duration-500 hover:scale-110 shadow-lg filter grayscale hover:grayscale-0 border-0 hover:border-indigo-500"

  },
  
  {
    "id": 4,
    "name": ".Weather App",
    "image": require("../../images/weatherAppPhoto.webp"),
    "description": "Using OpenWeather API to fetch the data, this weather dashboard will display a current and five day forcast for any searched location in the world.",
    "deployedLink": "https://sophie-senge.github.io/weather_app/",
    "githubLink": "https://github.com/Sophie-Senge/weather_app",
    "tools": [">HTML5",  ">CSS3", ">JavaScript", ">APIs", ">Jquery", ">MomentJS"],
    "className": "h-22 w-22 rounded-tl-extraLarge rounded-br-extraLarge cursor-pointer p-4 transition duration-500 hover:scale-110 shadow-lg filter grayscale hover:grayscale-0 border-0 hover:border-indigo-500"
  },
  {
    "id": 5,
    "name": ".My Portfolio",
    "image": require("../../images/Screenshot 2023-03-23 at 9.26.04 PM.png"),
    "description": "My Portfolio built with React, multiple libraries, and Tailwind. Deployed on Netlify.",
    "deployedLink": "https://sophieperks.com",
    "githubLink": "https://github.com/Sophie-Senge/portfolio",
    "tools": [">JavaScript", ">React", ">Tailwind"],
    "className": "h-22 w-22 rounded-tr-extraLarge rounded-bl-extraLarge cursor-pointer p-4 transition duration-500 hover:scale-110 shadow-lg filter grayscale hover:grayscale-0 border-0 hover:border-indigo-500"
  },
 

]
export default projects